module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/devmastery-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f37d8960c4321bb4d5fb68ed5e0f7aa3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/ubuntu/apps/devmastery-colabs/node_modules/gatsby-remark-images","id":"3db6ee2f-e3f5-539a-aabe-fa2cc8f57b07","name":"gatsby-remark-images","version":"6.14.0","modulePath":"/home/ubuntu/apps/devmastery-colabs/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":700,"disableBgImageOnAlpha":true,"disableBgImage":true,"backgroundColor":"none"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/ubuntu/apps/devmastery-colabs","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"disableBgImageOnAlpha":true,"disableBgImage":true,"backgroundColor":"none"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]

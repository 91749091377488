import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import AppWrapper from './src/components/AppWrapper'
import "./src/styles/global.css"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <AppWrapper>
      {element}
    </AppWrapper>
  )
}
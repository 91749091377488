import React from "react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import darkCode from 'prism-react-renderer/themes/nightOwl'
import lightCode from 'prism-react-renderer/themes/vsDark'
import * as styles from './code.module.css'
import { ThemeContext } from "../../context/ThemeContextProvider";

type PropsType = {
    codeString: string
    language: Language
}

const Button = (props: React.ComponentProps<'button'>) => (
    <button
        style={{
            position: "absolute",
            top: 0,
            right: 0,
            border: "none",
            boxShadow: "none",
            textDecoration: "none",
            margin: "8px",
            padding: "8px 12px",
            background: "#E2E8F022",
            // color: "white",
            borderRadius: "8px",
            cursor: "pointer",
            color: "#E2E8F0",
            fontSize: "14px",
            fontFamily: "sans-serif",
            lineHeight: "1",
        }}
        {...props}
    />
)

const copyToClipboard = (str:string) => {
    const el = document.createElement("textarea")
    el.value = str
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }

export const Code = ({ codeString, language, ...props }: PropsType) => {
    const [isCopied, setIsCopied] = React.useState(false)
    const {themeIsDark} = React.useContext(ThemeContext)
    const theme = themeIsDark ? lightCode : darkCode
    return (
        <Highlight {...defaultProps} code={codeString} language={language} theme={theme} >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <div className={styles.gatsby_highlight} data-language={language}>
                    <pre className={className} style={style}>

                        <Button
                            onClick={() => {
                                copyToClipboard(codeString)
                                setIsCopied(true)
                                setTimeout(() => setIsCopied(false), 3000)
                            }}
                        >
                            {isCopied ? "🎉 Copied!" : "Copy"}
                        </Button>

                        {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                                {line.map((token, key) => (
                                    <span {...getTokenProps({ token, key })} />
                                ))}
                            </div>
                        ))}
                    </pre>
                </div>
            )}
        </Highlight>
    );
};
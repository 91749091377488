import React from 'react'
// fill: lightseagreen;
// font-size: 1.2em:
const CheckIcon = (props: React.ComponentProps<'svg'>) => {
 return (
    <svg 
        stroke="currentColor" 
        fill="currentColor" 
        strokeWidth="0" 
        version="1.2" 
        baseProfile="tiny" 
        viewBox="0 0 24 24" 
        height="1em" 
        width="1em" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16 19h-8c-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h5c.553 0 1 .448 1 1s-.447 1-1 1h-5c-.552 0-1 .449-1 1v8c0 .551.448 1 1 1h8c.552 0 1-.449 1-1v-3c0-.552.447-1 1-1s1 .448 1 1v3c0 1.654-1.346 3-3 3zM13.166 14.833c-.35 0-.689-.139-.941-.391l-2.668-2.667c-.52-.521-.52-1.365 0-1.885.521-.521 1.365-.521 1.887 0l1.416 1.417 3.475-5.455c.357-.644 1.17-.877 1.814-.519.643.358.875 1.17.518 1.813l-4.334 7c-.203.366-.566.615-.98.673l-.187.014z"></path>
    </svg>
 )
}

export default CheckIcon

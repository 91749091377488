import React from "react"
import {Helmet} from 'react-helmet'


type PropsType = {
    children: React.ReactNode
}

type ThemeContextType = {
    themeIsDark: boolean
    setTheme: React.Dispatch<React.SetStateAction<boolean>>
}

export const ThemeContext = React.createContext<ThemeContextType>({} as ThemeContextType)


const ThemeContextProvider = ({children} : PropsType) => {
    const [themeIsDark, setTheme] = React.useState(false)

    React.useLayoutEffect(() => {
        setTheme(localStorage.getItem('theme') == 'dark')
    }, [])
    
    React.useLayoutEffect(() => {
        localStorage.setItem('theme', themeIsDark ? 'dark' : 'light')
    }, [themeIsDark])


    return (
        <ThemeContext.Provider value={{themeIsDark, setTheme}}>
            <Helmet htmlAttributes={{class: themeIsDark ? 'dark' : 'light'}} />
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;


import React from 'react'
import CheckIcon from './CheckIcon'

type PropsType = {
    children: React.ReactNode
}

function Task({children}:PropsType) {
    return (
        <h3 className="d-flex-align-start">
            <CheckIcon fill='lightseagreen' style={{ fontSize: '1.25em', minWidth: 30 }} /> {children}
        </h3>
    )
}

export default Task

import React from 'react'
import {MDXProvider} from '@mdx-js/react'
import {preToCodeBlock} from 'mdx-utils'
import { Code } from './Code/Code'
import ThemeContextProvider from '../context/ThemeContextProvider'

type PropsType = {
    children: React.ReactNode
}

const components = {
    pre: (preProps:any) => {
        const props = preToCodeBlock(preProps);
        // if there's a codeString and some props, we passed the test
        if (props) {
          return <Code {...props} />;
        } else {
          // it's possible to have a pre without a code in it
          return <pre {...preProps} />;
        }
    }
}

function AppWrapper({children}:PropsType) {
    return (
        <ThemeContextProvider>
            <MDXProvider components={components}>
                {children}
            </MDXProvider>
        </ThemeContextProvider>
    )
}

export default AppWrapper
